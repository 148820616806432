import Vue from 'vue'
import Router from 'vue-router'
import Cookies from 'js-cookie'
import { pushPageContext, pushScreenOpenedEvent } from '@/utils/gtm'
import {
  signInAsExternalUser,
  signOutAsEmployee,
  clearConfigurations,
  clearAuthTokens
} from '@/utils/auth'
import store from '../store'
import { loadLanguage, defaultLang, i18n } from '../i18n'
import accountDetailsRoutes from './account-details'
import userRoutes from './users'
import rolesRoutes from './roles'
import topUpsRoutes from './top-ups'
import b2CreditsRoutes from './b2c-credits'
import accountRoutes from './account'
import balanceRoutes from './balance'
import paymentMethodsRoutes from './payment-methods'
import walletAccountsRoutes from './wallet-accounts'
import accountsFundingRoutes from './accounts-funding'
import purchasesRoutes from './purchases'
import shopConfigurations from './shop-configurations'
import disputesRoutes from './disputes'
import settlements from './settlements'
import payByLink from './pay-by-link'
import { loadView } from './utils'
import { MAIN, SECONDARY } from './page-types'
import { getEnv } from '@/utils-common/env'
import { localStorageKeys, cookiesKeys } from '@/constants'
import { isLocalStorageAvailable } from '@/utils/localStorage'

Vue.use(Router)

const baseRoutes = [
  {
    path: '/',
    name: 'homepage',
    component: loadView('ViewHomepage'),
    meta: {
      pageType: MAIN
    }
  },
  {
    path: '/inactive',
    name: 'inactive',
    component: loadView('ViewAccountInactive'),
    meta: {
      pageType: MAIN
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...baseRoutes,
    {
      path: '/:areaId',
      component: loadView('ViewArea'),
      children: [
        {
          path: 'get-started',
          name: 'get-started',
          component: loadView('ViewGetStarted'),
          meta: {
            navigationDrawer: true,
            pageType: MAIN
          }
        },
        {
          path: 'get-started/submit-business-information',
          name: 'get-started-form',
          component: loadView('ViewGetStartedForm'),
          meta: {
            pageType: SECONDARY
          }
        },
        ...accountDetailsRoutes,
        ...userRoutes,
        ...rolesRoutes,
        ...topUpsRoutes,
        ...b2CreditsRoutes,
        ...accountRoutes,
        ...balanceRoutes,
        ...paymentMethodsRoutes,
        ...walletAccountsRoutes,
        ...accountsFundingRoutes,
        ...shopConfigurations,
        ...purchasesRoutes,
        ...disputesRoutes,
        ...payByLink,
        ...settlements
      ]
    }
  ]
})

function getBusinessAreaIdByPriority(to) {
  // 1. Priority - url
  if (to.params.areaId) return to.params.areaId

  // 2. Priority - localstorage
  const lastAreaAccessed = localStorage.getItem('selected_area')
  if (lastAreaAccessed) {
    const isAvailableYet = store.state.app.areas.find(
      (el) => el.id === lastAreaAccessed
    )
    if (isAvailableYet) return lastAreaAccessed
  }

  // 3. Priority - first Business Area returned by the api
  const [firstBa] = store.state.app.areas
  return firstBa.id
}

function isAccountRoute(to) {
  return ['account-connect', 'account-create'].includes(to.name)
}

function isInactiveRoute(to) {
  return to.name === 'inactive'
}

function isHomepageRoute(to) {
  return to.name === 'homepage'
}

export function getTheFirstOptionAvailable(lang, businessAreaId) {
  const firstOption = store.state.app.menu.find(
    (element) => element.to !== undefined || element.modules !== undefined
  )
  if (firstOption.modules) {
    const firstModuleRoute = firstOption.modules[0].to

    return {
      name: firstModuleRoute.name,
      params: { areaId: businessAreaId, ...firstModuleRoute.params },
      query: {
        lang
      }
    }
  } else {
    return {
      name: firstOption.to.name,
      params: { areaId: businessAreaId },
      query: {
        lang
      }
    }
  }
}

function hasMandatoryInformation() {
  const email = localStorage.getItem(localStorageKeys.email)
  const isEmployee = localStorage.getItem(localStorageKeys.isEmployee)

  const refreshToken = Cookies.get(cookiesKeys.refreshToken)
  const authToken = Cookies.get(cookiesKeys.authToken)

  return email && isEmployee && refreshToken && authToken
}

function nextHomePage(to, next) {
  if (getEnv('VUE_APP_FROSTY_URL')) {
    window.location.href =
      i18n.locale === defaultLang
        ? getEnv('VUE_APP_FROSTY_URL')
        : `${getEnv('VUE_APP_FROSTY_URL')}${i18n.locale}/`
  } else {
    if (isHomepageRoute(to)) {
      next()
    } else {
      next({ name: 'homepage', query: { lang: to.query.lang } })
    }
  }
}

async function nextAllowed(to, next) {
  if (!store.state.app.areas || store.state.app.areas.length === 0) {
    if (isAccountRoute(to) || isInactiveRoute(to)) {
      next()
    } else {
      next({ name: 'inactive', query: { lang: to.query.lang } })
    }
  } else {
    const businessAreaId = getBusinessAreaIdByPriority(to)

    if (store.state.app.businessAreaId !== businessAreaId) {
      try {
        // Load module locales and stores
        await loadStoreAndLanguages(to)

        await store.dispatch('app/getFeatures', businessAreaId)
      } catch (error) {
        // Any error getting the menu redirect
        // to the first Business Area available
        const [firstBa] = store.state.app.areas
        if (store.state.app.businessAreaId !== firstBa.id) {
          try {
            await store.dispatch('app/getFeatures', firstBa.id)
          } catch {
            await store.dispatch('app/signOut')
          }
        }

        next(getTheFirstOptionAvailable(to.query.lang, firstBa.id))
      }
    }

    // If trying to access the homepage or inactive page
    // redirect to the first
    if (isHomepageRoute(to) || isInactiveRoute(to)) {
      next(getTheFirstOptionAvailable(to.query.lang, businessAreaId))
    } else {
      next()
    }
  }
}

async function nextAllowedWhenLoginFails(to, error, next) {
  const { response: { status, data: { email } = {} } = {} } = error

  if (status === 403) {
    await store.commit('app/USER_UPDATED', { email })

    if (isAccountRoute(to)) {
      next()
    } else {
      isInactiveRoute(to)
        ? next()
        : next({ name: 'inactive', query: { lang: to.query.lang } })
    }
  } else {
    if (status === 401 && isAccountRoute(to)) {
      signInAsExternalUser()
    } else {
      clearAuthTokens()
      clearConfigurations()

      if (
        isAccountRoute(to) &&
        getEnv('VUE_APP_KEYCLOAK_LOGIN') &&
        getEnv('VUE_APP_KEYCLOAK_LOGIN').toLowerCase() === 'true'
      ) {
        const returnUrl = window.location.origin
        window.location.href = `${getEnv(
          'VUE_APP_NEW_AUTH_URL'
        )}/registrations?client_id=${getEnv(
          'VUE_APP_CLIENT_ID'
        )}&response_type=code&scope=openid&redirect_uri=${returnUrl}/account/create/?user_type=external`
      }
      nextHomePage(to, next)
    }
  }
}

function isEmployee() {
  return localStorage.getItem(localStorageKeys.isEmployee) === 'true'
}

router.beforeEach(async (to, from, next) => {
  if (!isLocalStorageAvailable()) {
    if (to.name === 'homepage') {
      next()
    } else {
      next({ name: 'homepage', query: { lang: to.query.lang } })
    }
  } else {
    if (from.name !== to.name) {
      // Load module locales
      await loadModuleLanguage(to)
      store.dispatch('app/loadingPageStart')
    }

    if (hasMandatoryInformation()) {
      if (from.name === null) {
        if (isEmployee()) {
          try {
            await store.dispatch('app/getBusinessAreas')
            nextAllowed(to, next)
          } catch (error) {
            signOutAsEmployee()
          }
        } else {
          try {
            await store.dispatch('app/login')
            nextAllowed(to, next)
          } catch (error) {
            nextAllowedWhenLoginFails(to, error, next)
          }
        }
      } else {
        nextAllowed(to, next)
      }
    } else {
      try {
        const authCode = to.query.code
        const userType = to.query.user_type

        await store.dispatch('app/login', { authCode, userType })

        if (authCode) {
          const newQueryParams = { ...to.query }
          delete newQueryParams.code
          delete newQueryParams.user_type
          next({ path: to.path, query: newQueryParams })
        } else {
          nextAllowed(to, next)
        }
      } catch (error) {
        nextAllowedWhenLoginFails(to, error, next)
      }
    }
  }
})

router.beforeResolve(async (to, from, next) => {
  const used = getEnv('VUE_APP_I18N_AVAILABLE_LOCALES')?.split(',') || []
  const queryLang = to.query?.lang

  // Load module locales and stores
  await loadStoreAndLanguages(to)

  // Update selected area
  if (to.params.areaId) {
    localStorage.setItem('selected_area', to.params.areaId)
  }

  if (queryLang && !used.includes(queryLang)) {
    next({
      ...to,
      query: { ...to.query, lang: undefined }
    })
  } else next()
})

async function loadStoreAndLanguages(to) {
  await loadModuleLanguage(to)

  // Load module store
  const module = to.meta.module
  if (module && to.meta.store !== false) {
    if (!(module in store._modules.root._children)) {
      const response = await import(`@/modules/${module}/_store`)
      store.registerModule(module, response.default)
    }
  }
}

async function loadModuleLanguage(to) {
  const module = to.meta.module

  const used = getEnv('VUE_APP_I18N_AVAILABLE_LOCALES')?.split(',') || []
  const queryLang = to.query?.lang
  const cookieLang = localStorage.getItem(localStorageKeys.lang)
  let lang = defaultLang

  if (queryLang) {
    if (used.includes(queryLang)) {
      lang = queryLang
      localStorage.setItem(localStorageKeys.lang, lang)
    }
  } else if (cookieLang && used.includes(cookieLang)) {
    lang = cookieLang
  }

  // Load module locales
  await loadLanguage({ module, lang })
}

router.afterEach((to) => {
  store.dispatch('app/loadingPageEnd')
  // Analytics - Track Page views
  Vue.nextTick(() => {
    pushPageContext(to.name, to.path, to.meta.pageType)
    pushScreenOpenedEvent(to.name, to.path)
  })
})

export default router
