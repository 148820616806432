/* istanbul ignore file */

export function downloadFile(response) {
  const contentDisposition = response.headers['content-disposition']
  const blob = new Blob([response.data], { type: response.data.type })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  let filename = 'unknown'
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(contentDisposition)
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '')
  }
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

export function downloadFileByUrl(url) {
  const link = document.createElement('a')
  link.href = url
  let filename = 'unknown'
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(url)
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, '')
  }
  link.setAttribute('download', filename)
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

export function downloadTemplate(rows, filename) {
  const csvContent =
    'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(';')).join('\n')
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename)
  document.body.appendChild(link) // Required for FF
  link.click() // This will download the data file named 'my_data.csv'.
  document.body.removeChild(link)
}
