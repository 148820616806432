<template>
  <div class="j-status text-center">
    <img :src="image" class="my-6" />

    <div
      class="text-h6 font-weight-bold mb-2"
      :class="{ 'word-break': $vuetify.breakpoint.xsOnly }"
    >
      {{ title }}
    </div>

    <div v-if="description" class="text-body-1 mb-6">
      {{ description }}
    </div>

    <v-btn
      v-if="actionLabel"
      data-cy="action-button"
      text
      block
      color="primary"
      class="mx-auto mt-0 mb-4"
      :href="actionUrl"
      @click="$emit('action-clicked')"
    >
      {{ actionLabel }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'JStatus',
  props: {
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: undefined
    },
    actionLabel: {
      type: String,
      default: undefined
    },
    actionUrl: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style scoped>
.j-status {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-h6 {
  color: #898997;
}
.text-body-1 {
  color: #8d8d92;
}
.word-break {
  word-break: break-word;
}
</style>
