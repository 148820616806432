<template>
  <VNavigationDrawer
    app
    permanent
    :width="width"
    :right="$vuetify.rtl"
    data-cy="v-navigation-drawer"
  >
    <!-- Application Details -->
    <template v-if="hasDetails">
      <div class="white--text primary pa-4">
        <div class="drawer-info">
          <div class="d-flex justify-space-between align-start">
            <img :src="logo" class="mb-1 flex-shrink-0" />
            <div v-if="locales.length > 1">
              <v-tabs v-model="tab">
                <template v-for="(item, i) in locales">
                  <v-tab
                    v-if="item.name"
                    :key="item.code"
                    class="white--text primary px-3 tab"
                    @click="languageChanged(item)"
                  >
                    {{ item.name }}
                  </v-tab>
                  <v-icon
                    v-if="i + 1 < locales.length"
                    :key="i"
                    style="width: 13px"
                    class="white--text primary"
                    >{{ mdiPowerOn }}</v-icon
                  >
                </template>
              </v-tabs>
            </div>
          </div>
        </div>
        <div class="text-body-1 font-weight-bold">{{ title }}</div>
        <div class="text-body-2 text-truncate">{{ subtitle }}</div>
      </div>
      <v-divider />
    </template>

    <template v-if="canCreateBa">
      <v-list-item
        data-cy="create-ba"
        class="primary"
        @click="$emit('create-ba')"
      >
        <v-list-item-icon class="my-3 mr-2">
          <v-icon class="white--text">{{ mdiPlus }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-body-2 font-weight-bold white--text">
            {{ createBaLabel }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </template>

    <!-- Current Workspace -->
    <v-list v-if="currentWorkspace" dense dark color="primary">
      <v-list-item data-cy="current-workspace" v-on="currentWorkspaceEvents">
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1">
            {{ currentWorkspace.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-uppercase">
            {{ currentWorkspace.description }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action
          v-if="hasSeveralWorkspaces || forceWorkspacesDropdown"
        >
          <v-icon>{{ isSelectingWorkspace ? mdiMenuUp : mdiMenuDown }}</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <div v-show="!loading">
      <!-- Available Workspaces -->
      <v-list v-show="isSelectingWorkspace" nav dense>
        <JAutocomplete
          class="separator"
          :label="searchLabel"
          :search-string="q"
          @q-changed="onWorkspaceSearch"
        />

        <v-list-item
          v-for="workspace in remainingWorkspaces"
          :key="workspace.id"
          two-line
          data-cy="other-workspace"
          @click="onWorkspaceSelected(workspace)"
        >
          <v-list-item-content>
            <v-list-item-title
              class="current-workspace-name text-subtitle-1 font-weight-bold"
            >
              {{ workspace.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              class="text-uppercase font-weight-regular text-caption"
            >
              {{ workspace.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <div
          v-if="remainingWorkspaces.length === 0"
          class="d-flex justify-center mt-4 font-weight-bold no-results"
        >
          {{ noResultsLabel }}
        </div>
      </v-list>

      <!-- Menu -->
      <v-list v-show="!isSelectingWorkspace" expand class="pt-0">
        <template v-for="(item, index) in menu">
          <template v-if="item.isGroup">
            <v-divider v-if="index > 0" :key="`divider_${index}`" />
            <v-subheader
              :key="`group_${index}`"
              data-cy="group"
              class="menu-group font-weight-bold text-uppercase pt-4 pb-2 text-caption"
            >
              {{ item.text }}
            </v-subheader>
          </template>

          <!-- Option with sub menu -->
          <v-list-group
            v-else-if="item.modules && item.modules.length > 0"
            :key="index"
            :group="resolvePartialPath(item.to)"
            :data-cy="item.code"
          >
            <template #activator>
              <v-icon :class="{ 'mr-4': !$vuetify.rtl, 'ml-4': $vuetify.rtl }">
                {{ item.icon }}
              </v-icon>
              <v-list-item-title class="text-body-2">
                {{ item.text }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="(subitem, subItemIndex) in item.modules"
              :key="`option_${subItemIndex}`"
              :data-cy="subitem.code"
              class="text-body-2"
              active-class="font-weight-bold"
              :to="subitem.to"
            >
              <v-list-item-icon class="my-3 mr-4">
                <v-icon>{{ subitem.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title style="font-size: inherit">
                  {{ subitem.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- Simple option -->
          <v-list-item
            v-else
            :key="`option_${index}`"
            color="primary"
            :data-cy="item.code"
            class="text-body-2"
            active-class="font-weight-bold"
            :to="item.to"
          >
            <v-list-item-icon class="my-3 mr-4">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="font-size: inherit">
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </div>

    <!-- Sign Out -->
    <template #append>
      <v-divider />
      <v-list-item
        data-cy="sign-out"
        :href="signOutUrl"
        @click="$emit('sign-out')"
      >
        <v-list-item-icon class="my-3 mr-3">
          <v-icon> {{ mdiExitToApp }} </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-body-2">
            {{ signOutLabel }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </VNavigationDrawer>
</template>

<script>
import {
  mdiExitToApp,
  mdiMenuUp,
  mdiMenuDown,
  mdiPowerOn,
  mdiPlus
} from '@mdi/js'
import { debounce } from 'lodash-es'
import JAutocomplete from '@/components/JAutocomplete/JAutocomplete'
export default {
  name: 'JNavigationDrawer',
  components: {
    JAutocomplete
  },
  props: {
    width: {
      type: String,
      default: '242'
    },
    logo: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    subtitle: {
      type: String,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    forceWorkspacesDropdown: {
      type: Boolean,
      default: false
    },
    locales: {
      type: Array,
      default: function () {
        return []
      }
    },
    selectedLocale: {
      type: Number,
      default: undefined
    },
    menu: {
      type: Array,
      validator: function (value) {
        return (
          value.length === 0 ||
          (value.length > 0 &&
            !value.find(
              (el) =>
                // All modules must have the "text" property
                !el.text ||
                // All normal modules must have a property "icon" and "to"
                (!el.isGroup && !el.modules && (!el.icon || !el.to)) ||
                // When a module has sub modules:
                // - it must contain an icon
                // - submodules can not be empty
                // - each submodule must have a property "text" and "to"
                (el.modules &&
                  (el.modules.length === 0 ||
                    !el.icon ||
                    el.modules.find((z) => !z.text || !z.to)))
            ))
        )
      },
      default: function () {
        return []
      }
    },
    workspaces: {
      type: Array,
      validator: function (value) {
        return (
          value.length === 0 ||
          (value.length > 0 && !value.find((el) => !el.id || !el.name))
        )
      },
      default: function () {
        return []
      }
    },
    currentWorkspace: {
      type: Object,
      validator: function (value) {
        return value === undefined || (value.id && value.name)
      },
      default: undefined
    },
    signOutUrl: {
      type: String,
      default: undefined
    },
    signOutLabel: {
      type: String,
      default: function () {
        return this.$t('sign_out')
      }
    },
    searchLabel: {
      type: String,
      default: function () {
        return this.$t('search_label')
      }
    },
    noResultsLabel: {
      type: String,
      default: function () {
        return this.$t('no_results_label')
      }
    },
    canCreateBa: {
      type: Boolean,
      default: false
    },
    createBaLabel: {
      type: String,
      default: function () {
        return this.$t('create_ba_label')
      }
    }
  },
  data() {
    return {
      mdiExitToApp,
      mdiMenuUp,
      mdiMenuDown,
      mdiPowerOn,
      mdiPlus,
      isSelectingWorkspace: false,
      workspaceQueryString: undefined,
      q: null,
      tab: this.selectedLocale
    }
  },
  computed: {
    currentWorkspaceEvents() {
      // Do not show cursor pointer when there is only one workspace
      return this.hasSeveralWorkspaces || this.forceWorkspacesDropdown
        ? { click: this.onCurrentWorkspaceClick }
        : {}
    },
    hasDetails() {
      return this.logo || this.title || this.subtitle
    },
    hasSeveralWorkspaces() {
      return this.q != null || this.workspaces.length > 1
    },
    remainingWorkspaces() {
      return this.workspaces.filter((el) => el.id !== this.currentWorkspace.id)
    }
  },
  methods: {
    onCurrentWorkspaceClick(event) {
      this.isSelectingWorkspace = !this.isSelectingWorkspace
    },
    onWorkspaceSelected(workspace) {
      this.$emit('workspace-selected', workspace.id)
      this.q = ''
      this.isSelectingWorkspace = false
    },
    onWorkspaceSearch: debounce(async function (value) {
      this.q = value
      this.$emit('workspace-search', value)
    }, 200),
    resolvePartialPath(to) {
      if (to) {
        return this.$router?.resolve({
          ...to,
          params: {
            ...to.params,
            areaId: this.currentWorkspace?.id
          }
        })?.resolved?.path
      }
    },
    languageChanged(item) {
      this.$emit('language-changed', item)
    }
  }
}
</script>

<style scoped>
.current-workspace-name {
  color: #8e8e93 !important;
}

.menu-group {
  color: #8e8e93;
  height: auto;
}

.drawer-info {
  max-height: 110px;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.v-list-group:not(.v-list-group--active) .v-list-item__title {
  color: #8e8e93 !important;
}

.separator {
  border-bottom: solid thin rgba(0, 0, 0, 0.12);
  margin: 0 -8px;
}

.no-results {
  color: #8e8e93;
}

.tab {
  min-width: auto;
  font-size: 13px;
  font-weight: normal;
}

.v-tab--active {
  font-weight: bold;
}
</style>
