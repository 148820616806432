function getStatus(error) {
  const { response: { status } = {} } = error
  return status
}

function getFormErrors(error) {
  const { response: { data: { errors } = {} } = {} } = error

  return errors
}

function getHeaders(response) {
  const { headers } = response

  return headers
}

function isBadRequest(error) {
  return getStatus(error) === 400
}

function isUnauthorized(error) {
  return getStatus(error) === 401
}

function isForbidden(error) {
  return getStatus(error) === 403
}

function isNotFound(error) {
  return getStatus(error) === 404
}

function isConflict(error) {
  return getStatus(error) === 409
}

function isExpired(error) {
  return getStatus(error) === 410
}

function isUnprocessable(error) {
  return getStatus(error) === 422
}

function hasFormErrors(error) {
  const errors = getFormErrors(error)

  if (errors) return true
  return false
}

function hasAttachment(response) {
  const { 'content-disposition': contentDisposition } =
    getHeaders(response) || {}

  return contentDisposition && contentDisposition.indexOf('attachment') !== -1
}

function getErrorMessage(error) {
  const { response: { data: { message } = {} } = {} } = error
  return message
}

function mapFormErrors(error) {
  const errors = getFormErrors(error)

  const map = {}
  if (errors) {
    errors.forEach((item) => {
      map[item.field] = item.errors[0].message
    })
  }
  return map
}

export {
  isUnauthorized,
  isNotFound,
  isBadRequest,
  isForbidden,
  isConflict,
  isExpired,
  isUnprocessable,
  hasAttachment,
  hasFormErrors,
  mapFormErrors,
  getErrorMessage
}
