/* istanbul ignore file */

import Cookies from 'js-cookie'
import axios from 'axios'
import { errorHandler } from './axios-interceptors'
import { getEnv } from '@/utils-common/env'
import qs from 'qs'
import { cookiesKeys } from '@/constants/index'
import { isCookiesAvailable } from '../utils/cookies'

// Common configurations
axios.defaults.withCredentials = true
axios.defaults.headers.common['Accept-Language'] = getEnv('VUE_APP_I18N_LOCALE')
axios.defaults.headers.common.Application = 'pay_business_area'
axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'repeat' })
}

// Axios instances
const warioAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'test' ? '' : getEnv('VUE_APP_WARIO_API_ENDPOINT'),
  headers: {
    apikey: getEnv('VUE_APP_WARIO_API_KEY')
  }
})

// TOP-UPS API
const moneyboxAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'test'
      ? ''
      : getEnv('VUE_APP_MONEYBOX_API_ENDPOINT'),
  headers: {
    apikey: getEnv('VUE_APP_MONEYBOX_API_KEY')
  }
})

// Approvals API
const rosalinaAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'test'
      ? ''
      : getEnv('VUE_APP_ROSALINA_API_ENDPOINT'),
  headers: {
    apikey: getEnv('VUE_APP_ROSALINA_API_KEY')
  }
})

const payByLinkAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'test'
      ? ''
      : getEnv('VUE_APP_PAY_BY_LINK_API_ENDPOINT')
})

// CMS API
const nabbitAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'test' ? '' : getEnv('VUE_APP_NABBIT_API_ENDPOINT')
})

// B2C Credits API
const fortknoxAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'test'
      ? ''
      : getEnv('VUE_APP_FORTKNOX_API_ENDPOINT'),
  headers: {
    apikey: getEnv('VUE_APP_FORTKNOX_API_KEY')
  }
})

const bowserAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'test' ? '' : getEnv('VUE_APP_BOWSER_API_ENDPOINT')
})

const toadAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'test' ? '' : getEnv('VUE_APP_TOAD_API_ENDPOINT')
})

if (isCookiesAvailable()) {
  const storedAuthToken = Cookies.get(cookiesKeys.authToken)
  const storedAuthNToken = Cookies.get(cookiesKeys.authNToken)
  if (storedAuthToken) {
    setAxiosXAuthToken(storedAuthToken, storedAuthNToken)
  }
}

// Interceptors
function bypassHandler(response) {
  return response
}
axios.interceptors.response.use(bypassHandler, errorHandler)
warioAxios.interceptors.response.use(bypassHandler, errorHandler)
moneyboxAxios.interceptors.response.use(bypassHandler, errorHandler)
rosalinaAxios.interceptors.response.use(bypassHandler, errorHandler)
payByLinkAxios.interceptors.response.use(bypassHandler, errorHandler)
fortknoxAxios.interceptors.response.use(bypassHandler, errorHandler)
bowserAxios.interceptors.response.use(bypassHandler, errorHandler)
toadAxios.interceptors.response.use(bypassHandler, errorHandler)

// Auxiliary methods
function setAxiosXAuthToken(token, authNToken) {
  warioAxios.defaults.headers.common['X-Auth-Token'] = token
  moneyboxAxios.defaults.headers.common['X-Auth-Token'] = token
  rosalinaAxios.defaults.headers.common['X-Auth-Token'] = token
  payByLinkAxios.defaults.headers.common['X-Auth-Token'] = token
  fortknoxAxios.defaults.headers.common['X-Auth-Token'] = token
  bowserAxios.defaults.headers.common['X-Auth-Token'] = token

  toadAxios.defaults.headers.common.Authorization = `Bearer ${token}`
  if (authNToken && authNToken !== 'null') {
    toadAxios.defaults.headers.common['X-AuthN-Token'] = authNToken
  }
  toadAxios.defaults.headers.common['X-Auth-Token'] = token
}

function setAxiosXBusinessArea(areaId) {
  warioAxios.defaults.headers.common['X-Business-Area'] = areaId
  moneyboxAxios.defaults.headers.common['X-Business-Area'] = areaId
  rosalinaAxios.defaults.headers.common['X-Business-Area'] = areaId
  payByLinkAxios.defaults.headers.common['X-Business-Area'] = areaId
  fortknoxAxios.defaults.headers.common['X-Business-Area'] = areaId
  bowserAxios.defaults.headers.common['X-Business-Area'] = areaId
}

function setAxiosAcceptLanguage(lang) {
  warioAxios.defaults.headers.common['Accept-Language'] = lang
  moneyboxAxios.defaults.headers.common['Accept-Language'] = lang
  rosalinaAxios.defaults.headers.common['Accept-Language'] = lang
  payByLinkAxios.defaults.headers.common['Accept-Language'] = lang
  fortknoxAxios.defaults.headers.common['Accept-Language'] = lang
  bowserAxios.defaults.headers.common['Accept-Language'] = lang
  toadAxios.defaults.headers.common['Accept-Language'] = lang
}

export {
  warioAxios,
  moneyboxAxios,
  nabbitAxios,
  rosalinaAxios,
  payByLinkAxios,
  fortknoxAxios,
  bowserAxios,
  toadAxios,
  setAxiosXAuthToken,
  setAxiosXBusinessArea,
  setAxiosAcceptLanguage
}
