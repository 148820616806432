import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en.json'
import errors from '@/locales/errors.en.json'
import { setAxiosAcceptLanguage } from '@/api/axios-configurations'
import { getEnv } from '@/utils-common/env'

Vue.use(VueI18n)

export const defaultLang = getEnv('VUE_APP_I18N_LOCALE') || 'en'

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      ...en,
      errors
    }
  }
})

export function getLanguages() {
  const all = [
    {
      code: 'en',
      name: 'En',
      rtl: false
    },
    {
      code: 'fr',
      name: 'Fr',
      rtl: false
    },
    {
      code: 'ar-EG',
      name: 'عربي',
      rtl: true
    },
    {
      code: 'ar-MA',
      name: 'عربي',
      rtl: true
    }
  ]

  const used = getEnv('VUE_APP_I18N_AVAILABLE_LOCALES')?.split(',') || []

  return all.filter((el) => used.includes(el.code))
}

function setI18nLanguage(lang) {
  i18n.locale = lang
  setAxiosAcceptLanguage(lang)
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

async function loadModuleLanguage({ module, lang }) {
  if (!i18n.messages[lang][module]) {
    const msgs = await import(
      /* webpackChunkName: "lang-[request]" */ `@/locales/${module}.${lang}.json`
    )
    i18n.mergeLocaleMessage(lang, {
      [module]: msgs.default
    })
  }
}

export async function loadLanguage({ module, lang = defaultLang }) {
  if (!i18n.messages[lang]) {
    const msgs = await import(
      /* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`
    )

    i18n.setLocaleMessage(lang, msgs.default)
    await loadModuleLanguage({ module: 'errors', lang })
    await loadModuleLanguage({ module: 'get-started', lang })
    if (module) {
      await loadModuleLanguage({ module, lang })
    }
  } else if (module) {
    await loadModuleLanguage({ module, lang })
  }
  if (!i18n.messages[lang]['get-started']) {
    await loadModuleLanguage({ module: 'get-started', lang })
  }
  setI18nLanguage(lang)
}
