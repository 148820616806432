<template>
  <v-snackbar
    :value="value"
    top
    :timeout="timeout"
    :color="backgroundColor"
    :absolute="absolute"
    :data-cy="type"
    @input="$emit('input', $event)"
  >
    <div>
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="d-flex align-center font-weight-medium"
      >
        <v-icon class="mr-2" :color="textColor">
          {{ icon }}
        </v-icon>
        <span :style="{ color: textColor }">
          {{ message }}
        </span>
      </div>
    </div>

    <template #action="{ attrs }">
      <v-btn data-cy="close" text v-bind="attrs" @click="$emit('input', false)">
        <v-icon :color="textColor">{{ mdiClose }}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mdiClose } from '@mdi/js'
export default {
  name: 'JNotificationBar',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 3000
    },
    absolute: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array,
      default: undefined
    },
    backgroundColor: {
      type: String,
      default: undefined
    },
    textColor: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'notification'
    }
  },
  data() {
    return {
      mdiClose
    }
  }
}
</script>
