<template>
  <div class="flex-grow-1" data-cy="autocomplete">
    <v-text-field
      v-model="q"
      solo
      flat
      clearable
      hide-details
      :clear-icon="mdiClose"
      :prepend-inner-icon="mdiMagnify"
      :label="label"
      :loading="loading"
    />
  </div>
</template>

<script>
import { mdiMagnify, mdiClose } from '@mdi/js'
export default {
  name: 'JAutocomplete',
  props: {
    searchString: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    minChars: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      mdiClose,
      mdiMagnify,
      q: null
    }
  },
  watch: {
    searchString(val) {
      this.q = val
    },
    q(value) {
      if (value === '' || !value || value.length >= this.minChars) {
        /** Search string changed */
        this.$emit('q-changed', value)
      }
    }
  }
}
</script>
