import Cookies from 'js-cookie'
import { getEnv } from '@/utils-common/env'
import { localStorageKeys, cookiesKeys } from '@/constants'
import { i18n, defaultLang } from '../i18n'
import { toadAxios } from '@/api/axios-configurations'

export function clearConfigurations() {
  localStorage.removeItem(localStorageKeys.currencyCode)
  localStorage.removeItem(localStorageKeys.currencySymbol)
  localStorage.removeItem(localStorageKeys.currencyDecimalPlaces)
  localStorage.removeItem(localStorageKeys.callingCode)
}

export function clearAuthTokens() {
  Cookies.remove(cookiesKeys.authToken)
  Cookies.remove(cookiesKeys.authNToken)
  Cookies.remove(cookiesKeys.refreshToken)
  Cookies.remove(cookiesKeys.idToken)

  localStorage.removeItem(localStorageKeys.email)
  localStorage.removeItem(localStorageKeys.isEmployee)
  localStorage.removeItem(localStorageKeys.isSupport)
}

function signInAsExternalUser(url) {
  clearConfigurations()

  const isKeycloakLogin =
    getEnv('VUE_APP_KEYCLOAK_LOGIN') &&
    getEnv('VUE_APP_KEYCLOAK_LOGIN').toLowerCase() === 'true'

  const lang = i18n.locale
  const countryCode = localStorage.getItem(localStorageKeys.countryCode)
  const country =
    lang === 'en'
      ? isKeycloakLogin
        ? '-GB'
        : `-${countryCode}`
      : lang === 'fr'
      ? isKeycloakLogin
        ? '-FR'
        : `-${countryCode}`
      : ''

  let returnUrl = url

  if (!url) {
    returnUrl = window.location.origin + window.location.pathname

    if (window.location.search) {
      returnUrl += window.location.search
    }
  }

  if (i18n.locale !== defaultLang) {
    if (window.location.search) {
      returnUrl += encodeURIComponent('&lang=') + i18n.locale
    } else {
      returnUrl += encodeURIComponent('?lang=') + i18n.locale
    }
  }

  if (isKeycloakLogin) {
    window.location.href = `${getEnv(
      'VUE_APP_NEW_AUTH_URL'
    )}/auth?client_id=${getEnv(
      'VUE_APP_CLIENT_ID'
    )}&response_mode=query&response_type=code&scope=openid&redirect_uri=${returnUrl}?user_type=external`
  } else {
    const clientId = getEnv('VUE_APP_CLIENT_ID')
    window.location.href = `${getEnv(
      'VUE_APP_AUTH_URL'
    )}/auth?locale=${lang}${country}&client_id=${clientId}&scope=openid%20email%20phone%20profile%20offline_access%20session&response_type=code&prompt=login&redirect_uri=${returnUrl}`
  }
}

function signInAsEmployee(url) {
  clearConfigurations()

  let returnUrl = url

  if (!url) {
    returnUrl = window.location.origin + window.location.pathname

    if (window.location.search) {
      returnUrl += window.location.search
    }
  }

  if (i18n.locale !== defaultLang) {
    if (window.location.search) {
      returnUrl += encodeURIComponent('&lang=') + i18n.locale
    } else {
      returnUrl += encodeURIComponent('?lang=') + i18n.locale
    }
  }

  window.location.href = `${getEnv(
    'VUE_APP_ACL_URL'
  )}login?redirect=${returnUrl}%3Fuser_type%3Dinternal&application=PAY_BUSINESS_AREA&aclFlow=v2`
}

function signOutAsExternalUser(url) {
  const idToken = Cookies.get(cookiesKeys.idToken)
  clearAuthTokens()
  clearConfigurations()

  const isKeycloakLogin =
    getEnv('VUE_APP_KEYCLOAK_LOGIN') &&
    getEnv('VUE_APP_KEYCLOAK_LOGIN').toLowerCase() === 'true'

  let returnUrl =
    url ||
    (getEnv('VUE_APP_FROSTY_URL')
      ? getEnv('VUE_APP_FROSTY_URL')
      : window.location.origin)

  if (i18n.locale !== defaultLang) {
    if (getEnv('VUE_APP_FROSTY_URL') && !url) {
      returnUrl += `${i18n.locale}/`
    } else {
      returnUrl = `${returnUrl}${encodeURIComponent('?lang=')}${i18n.locale}`
    }
  }

  if (isKeycloakLogin) {
    window.location.href = `${getEnv(
      'VUE_APP_NEW_AUTH_URL'
    )}/logout?scope=openid&id_token_hint=${idToken}&post_logout_redirect_uri=${returnUrl}`
  } else {
    const payload = {
      context: 'business-area',
      user_type: 'external'
    }
    toadAxios.post('/logout', payload).then(() => {
      window.location.href = `${getEnv(
        'VUE_APP_AUTH_URL'
      )}/session/end?client_id=${getEnv('VUE_APP_CLIENT_ID')}&post_logout_redirect_uri=${window.location.origin}`
    })
  }
}

function signOutAsEmployee() {
  clearAuthTokens()
  clearConfigurations()

  let returnUrl = getEnv('VUE_APP_FROSTY_URL')
    ? getEnv('VUE_APP_FROSTY_URL')
    : window.location.origin

  if (i18n.locale !== defaultLang) {
    if (getEnv('VUE_APP_FROSTY_URL')) {
      returnUrl += `${i18n.locale}/`
    } else {
      returnUrl = `${returnUrl}${encodeURIComponent('?lang=')}${i18n.locale}`
    }
  }

  window.location.href = `${getEnv(
    'VUE_APP_ACL_URL'
  )}login?redirect=${returnUrl}&application=PAY_BUSINESS_AREA&aclFlow=v2&logout=true&redirectAfterLogout=true`
}

export {
  signInAsExternalUser,
  signOutAsExternalUser,
  signInAsEmployee,
  signOutAsEmployee
}
