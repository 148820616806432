<template>
  <v-bottom-navigation :value="activeBtn" app :input-value="isVisible">
    <v-btn
      v-for="(option, index) in menu"
      :key="index"
      :to="option.to"
      color="primary"
      text
      exact
    >
      <span>{{ option.text }} </span>
      <v-icon>{{ option.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'JBottomNavigation',
  props: {
    menu: {
      type: Array,
      required: true,
      validator: function (value) {
        return (
          value.length <= 3 &&
          !value.find((el) => !el.text || !el.icon || !el.to)
        )
      }
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeBtn() {
      if (this.menu) {
        return Math.min(this.menu.length, 2)
      }
      return 0
    }
  }
}
</script>
