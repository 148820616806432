import { localStorageKeys } from '@/constants'
import { isLocalStorageAvailable } from '@/utils/localStorage'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  loadingPage: false,
  email: isLocalStorageAvailable()
    ? localStorage.getItem(localStorageKeys.email)
    : null,
  currencyCode: isLocalStorageAvailable()
    ? localStorage.getItem(localStorageKeys.currencyCode)
    : null,
  currencySymbol: isLocalStorageAvailable()
    ? localStorage.getItem(localStorageKeys.currencySymbol)
    : null,
  currencyDecimalPlaces: isLocalStorageAvailable()
    ? localStorage.getItem(localStorageKeys.currencyDecimalPlaces)
    : 0,
  callingCode: isLocalStorageAvailable()
    ? localStorage.getItem(localStorageKeys.callingCode)
    : null,
  countryCode: isLocalStorageAvailable()
    ? localStorage.getItem(localStorageKeys.countryCode)
    : null,
  areas: null,
  areasFiltered: null,
  menu: null,
  businessAreaId: null,
  businessAreaSelected: {
    id: null,
    name: null,
    description: null
  },
  isReadOnly: false,
  permissions: [],
  menuLoading: false,
  snackbar: {
    type: undefined,
    icon: undefined,
    messages: undefined,
    textColor: undefined,
    backgroundColor: undefined
  },
  cmsBlock: {},
  globalAlertMessage: undefined,
  shops: null,
  isEmployee: isLocalStorageAvailable()
    ? localStorage.getItem(localStorageKeys.isEmployee) === 'true'
    : false,
  isSupport: isLocalStorageAvailable()
    ? localStorage.getItem(localStorageKeys.isSupport) === 'true'
    : false
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
