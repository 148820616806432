<template>
  <v-app>
    <JNavigationDrawer
      v-if="showNavigationDrawer"
      :key="navRerenderKey"
      :title="title"
      :width="width"
      :logo="require(`@/assets/logos/${$companyLegalName}/logo-white.png`)"
      :subtitle="email"
      :workspaces="businessAreas"
      :current-workspace="businessAreaSelected"
      :force-workspaces-dropdown="isSupport"
      :menu="menu"
      :loading="menuLoading"
      :locales="locales"
      :selected-locale="selectedLocale"
      :can-create-ba="isEmployee"
      @create-ba="onCreateBusinessArea"
      @sign-out="signOut"
      @workspace-selected="onAreaSelected"
      @workspace-search="onWorkspaceSearch"
      @language-changed="languageChanged"
    />

    <v-main v-if="showContent" :style="vContentStyles">
      <JNotificationBar
        v-model="isSnackbarActive"
        :icon="snackbar.icon"
        :messages="snackbar.messages"
        :text-color="snackbar.textColor"
        :background-color="snackbar.backgroundColor"
        :type="snackbar.type"
        :absolute="$route.meta && $route.meta.navigationDrawer"
      />
      <JLoading v-if="loadingPage" :text="$t('loading_page')" />

      <div v-else-if="hasPermissionToRoute" class="app-container">
        <router-view :key="contentRerenderKey"></router-view>
      </div>
      <JStatus
        v-else
        :image="lockImage"
        class="mx-auto"
        data-cy="no-permission"
        :title="$t('no_permissions.title')"
        :description="$t('no_permissions.description')"
        :action-label="$t('go_back')"
        @action-clicked="$router.go(-1)"
      />
    </v-main>

    <JBottomNavigation
      v-show="showBottomNavigation"
      :menu="availablePagesOnMenu || []"
      :is-visible="showBottomNavigation"
    />

    <AppAddToHomeScreen />

    <v-snackbar v-model="newVersion" right color="primary" :timeout="-1">
      {{ $t('new_version') }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="refreshApp">
          {{ $t('refresh') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import JLoading from '@/components/JLoading/JLoading'
import JStatus from '@/components/JStatus/JStatus'
import AppAddToHomeScreen from '@/components/AppAddToHomeScreen/AppAddToHomeScreen'
import { isForbidden } from '@/utils-common/errors'
import lockImage from '@/assets/lock.png'
import { getLanguages, loadLanguage } from '@/i18n'
import { localStorageKeys } from '@/constants'
import { mdiWeb, mdiClose } from '@mdi/js'
import { getEnv } from '@/utils-common/env'
import JNavigationDrawer from '@/components/JNavigationDrawer/JNavigationDrawer'
import JNotificationBar from '@/components/JNotificationBar/JNotificationBar'
import JBottomNavigation from '@/components/JBottomNavigation/JBottomNavigation'

export default {
  name: 'App',
  components: {
    JStatus,
    JLoading,
    AppAddToHomeScreen,
    JNavigationDrawer,
    JNotificationBar,
    JBottomNavigation
  },
  data() {
    return {
      fab: null,
      navRerenderKey: 1,
      contentRerenderKey: 2,
      mdiWeb,
      mdiClose,
      lockImage,
      newVersion: false,
      isSnackbarActive: false,
      locales: getLanguages(),
      title: getEnv('VUE_APP_NAME') + ' Business'
    }
  },
  computed: {
    ...mapState('app', [
      'loadingPage',
      'email',
      'areas',
      'menu',
      'businessAreaId',
      'menuLoading',
      'snackbar',
      'isSupport',
      'businessAreaSelected',
      'isEmployee'
    ]),
    ...mapGetters({
      availablePagesOnMenu: 'app/availablePagesOnMenu',
      hasPermission: 'app/hasPermission',
      businessAreas: 'app/businessAreas'
    }),
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    width() {
      if (this.isDesktop) {
        return this.$vuetify.breakpoint.lgAndUp ? '325' : '242'
      }
      return '100%'
    },
    isMenuHash() {
      return this.$route.hash === '#menu'
    },
    vContentStyles() {
      return {
        'background-color':
          !this.$route.meta.navigationDrawer ||
          (this.isMenuHash && !this.isDesktop)
            ? 'white'
            : '#fafafa'
      }
    },
    showNavigationDrawer() {
      return (
        (this.$route.meta.navigationDrawer && this.isDesktop) ||
        (this.isMenuHash && !this.isDesktop)
      )
    },
    showContent() {
      return this.isDesktop || (!this.isMenuHash && !this.isDesktop)
    },
    showBottomNavigation() {
      return (
        !this.isDesktop &&
        this.$route.meta.navigationDrawer &&
        this.availablePagesOnMenu.length > 0
      )
    },
    hasPermissionToRoute() {
      if (typeof this.$route.meta.role === 'function') {
        return this.hasPermission(this.$route.meta.role())
      } else {
        return this.hasPermission(this.$route.meta.role)
      }
    },
    lang() {
      return this.$i18n.locale
    },
    selectedLocale() {
      return this.locales.findIndex((el) => el.code === this.$i18n.locale)
    }
  },
  watch: {
    snackbar: {
      deep: true,
      handler(value) {
        if (value.messages) {
          this.isSnackbarActive = true
        }
      }
    },
    isSnackbarActive(value) {
      if (!value) {
        this.cleanSnackbar()
      }
    },
    lang(value) {
      const lang = this.locales.find((el) => el.code === value)
      this.$vuetify.rtl = lang.rtl
    }
  },
  created() {
    window.addEventListener('new-service-worker', (e) => {
      this.newVersion = true
    })

    this.updateManifest()
  },
  methods: {
    ...mapActions({
      getFeatures: 'app/getFeatures',
      searchBusinessAreas: 'app/searchBusinessAreas',
      login: 'app/login',
      showErrorNotification: 'app/showErrorNotification',
      cleanSnackbar: 'app/cleanSnackbar',
      signOut: 'app/signOut'
    }),
    async onAreaSelected(areaId) {
      try {
        const { menu } = await this.getFeatures(areaId)
        const firstOption = menu.find(
          (element) => element.to !== undefined || element.modules !== undefined
        )
        if (firstOption.modules) {
          const firstModuleRoute = firstOption.modules[0].to
          this.$router.push({
            name: firstModuleRoute.name,
            params: { areaId, ...firstModuleRoute.params }
          })
        } else {
          this.$router.push({ name: firstOption.to.name, params: { areaId } })
        }
      } catch (error) {
        if (isForbidden(error)) {
          this.signOut()
          this.showErrorNotification({
            message: this.$t('no_permissions.perform_action')
          })
        } else {
          this.showErrorNotification({
            message: this.$t('error.notification')
          })
          throw error
        }
      }
    },
    async languageChanged(newLang) {
      localStorage.setItem(localStorageKeys.lang, newLang.code)
      await loadLanguage({
        module: this.$route.meta.module,
        lang: newLang.code
      })

      if (this.$route.query.lang) {
        const query = Object.assign({}, this.$route.query)
        delete query.lang
        this.$router.replace({ query })
      }
      this.getFeatures(this.businessAreaId)
      this.navRerenderKey++
      this.contentRerenderKey++
    },
    onWorkspaceSearch(searchString) {
      this.searchBusinessAreas({ searchString })
    },
    refreshApp() {
      location.reload()
    },
    updateManifest() {
      // manifest
      document
        .querySelector('link[rel="manifest"]')
        ?.setAttribute(
          'href',
          `${getEnv('BASE_URL')}${this.$companyLegalName}/manifest.json`
        )

      // favicon
      document
        .getElementById('fav-icon')
        ?.setAttribute(
          'href',
          `${getEnv('BASE_URL')}${this.$companyLegalName}/icons/favicon.ico`
        )

      // icon 32x32
      document
        .querySelector('link[rel="icon"][sizes="32x32"]')
        ?.setAttribute(
          'href',
          `${getEnv('BASE_URL')}${
            this.$companyLegalName
          }/icons/favicon-32x32.png`
        )

      // icon 16x16
      document
        .querySelector('link[rel="icon"][sizes="16x16"]')
        ?.setAttribute(
          'href',
          `${getEnv('BASE_URL')}${
            this.$companyLegalName
          }/icons/favicon-16x16.png`
        )

      // apple-touch-icon
      document
        .querySelector('link[rel="apple-touch-icon"]')
        ?.setAttribute(
          'href',
          `${getEnv('BASE_URL')}${
            this.$companyLegalName
          }/icons/apple-touch-icon.png`
        )

      // mask-icon
      document
        .querySelector('link[rel="mask-icon"]')
        ?.setAttribute(
          'href',
          `${getEnv('BASE_URL')}${
            this.$companyLegalName
          }/icons/safari-pinned-tab.svg`
        )
    },
    onCreateBusinessArea() {
      this.$router.push({ name: 'account-create' })
    }
  }
}
</script>
<style>
.app-container {
  height: 100%;
}

.app-form-section {
  letter-spacing: 1px;
  color: #8e8e93;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
