import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { getEnv } from '@/utils-common/env'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: {
        primary: getEnv('VUE_APP_THEME') || '#005fd4',
        error: '#b81a0a'
      }
    }
  }
})
